/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

      //toggle search
      $( ".js--libby-search-trigger" ).click(function() {
        $( '.libby-search-overlay-wrapper' ).addClass( "libby-search-overlay-wrapper--is-open" );
      });

      $( ".js-libby-close-search-trigger" ).click(function() {
        $( '.libby-search-overlay-wrapper' ).removeClass( "libby-search-overlay-wrapper--is-open" );
      });

      //Hero Slider
        function colorActive(index) {
          $('.related-content div.mass-hero-slide-item--text').removeClass('active').eq(index).addClass('active');
        }

        $(document).ready(function(){
          //sponsor carousel

          var $scarousel = $(".sponsor_slider");
          if($scarousel){
            $scarousel.owlCarousel({
              nav: true,
              dots: true,
              loop: true,
              items: 4
            });
          }  

          var $carousel = $(".owl-carousel--hero");
          var index = 0;
          colorActive(index);
          $carousel.owlCarousel({
              nav: false,
              dots: true,
              items: 1
          });

          $carousel.on('changed.owl.carousel', function(event) {
                index = event.item.index;
                colorActive(index);
          });

          $('.related-content').find('div.mass-hero-slide-item--text').on('click', function(){
              var text_index = $(this).attr('data-target');
              $carousel.trigger('to.owl.carousel', text_index);
              colorActive(text_index);
          });


          








        });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
      //  $('.sponsor_slider').owlCarousel({
      //     items: 3,
      //     loop: true,
      //     nav: true,
      //     navText : ["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"]
      //   });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
